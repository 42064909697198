import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18n';
import App from './App';

window.cakepool = {
  data: {},
};
ReactDOM.render(<App />, document.getElementById('root'));
